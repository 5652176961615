<!-- eslint-disable -->
<template>
  <div>
    <VTitle :title="$t('app.feedback')" class="section-title" />

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <VAlert
      v-else-if="data.length === 0"
      :text="$t('app.no_feedback')"
    />

    <div v-else class="mt-5 relative">
      <div
        v-for="(review, index) in data"
        :key="review.id"
        :class="{ 'mb-3': index !== data.length - 1 }"
      >
        <FeedbackReview :review="review">
          <template v-if="$slots.avatar" #avatar>
            <slot name="avatar" :review="review" />
          </template>

          <template v-if="$slots.name" #name>
            <slot name="name" :review="review" />
          </template>
        </FeedbackReview>
      </div>

      <FullPagination
        v-if="!hidePagination"
        :pagination="pagination"
        @update:current_page="onUpdateCurrentPage"
        @update:per_page="onUpdatePerPage"
      />
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
// Composables
import useReadMultiple from "@/composables/useReadMultiple";
import usePaginate from "@/composables/usePaginate";
// Components
import VTitle from "@/components/VTitle";
import VAlert from "@/components/VAlert";
import FullPagination from "@/components/tables/FullPagination";
import FeedbackReview from "@/components/FeedbackReview";

export default {
  components: {
    FeedbackReview,
    VTitle,
    VAlert,
    FullPagination
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    hidePagination: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // Composables
    const { data, pagination, isLoading, read } = useReadMultiple();

    // Methods
    const getData = async () => {
      await read({
        endpoint: `${props.endpoint}.feedback`,
        pathParams: {
          id: props.id
        }
      });
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    const { onUpdateCurrentPage, onUpdatePerPage } = usePaginate(
      pagination,
      getData
    );

    return {
      // usePaginate,
      onUpdateCurrentPage,
      onUpdatePerPage,
      // useReadMultiple
      data,
      pagination,
      isLoading
    };
  }
};
</script>
